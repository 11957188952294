html,body{
    height: 100%;
}

body{
    position: relative;
}

* {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a{
    text-decoration: none;
    &:hover{
        color:#fff;
    }
}

.container{
    position: relative;
    width: 1140px;
    margin: 0 auto;
}

.gray{
    background:#ebebeb;
    min-height: 192px;
    width: 100%;
}