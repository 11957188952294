@import '_variables';

.title{
    font-size: 37px;
    &_fz60{
        font-size: 60px;
    }
    &_fz18{
        font-size: 18px;
    }
    &_main{
        font-size: 18px;
        color:$main-color;
        font-weight: 700;
        letter-spacing:.3rem ;
        text-transform: uppercase;
    }
    &_subtitle{
        font-weight: bold;
        line-height: 35px;
    }

    &_text{
        text-align: center;
        width: 650px;
        font-size: 20px;
        margin: 0 auto;
        margin-top: 30px;
        line-height: 2.3rem;
        color:#585858;
    }
}