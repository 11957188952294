.portfolio{
    padding: 100px 0;
    &__title{
        text-align: center;
    }
    &__subtitle{
        text-align: center;
    }

    &__wrapper{
        margin-top: 70px;
        display: flex;
        justify-content: center;
    }

    &__row{
        &-first{
            display: grid;
            grid-template-columns: 550px;
            grid-template-rows:687.5px repeat(2,550px);
            grid-auto-rows:550px
        }
        &-second{
            display: grid;
            grid-template-columns: 550px;
            grid-template-rows:repeat(2,550px) 687.5px;
        }
    }

    &__item{
        position: relative;
        overflow: hidden;
        &-img{
            width: 100%;
            height: 100%;
            transition: 0.5s all;
            z-index:1;
            transform: scale(1.1);
            img{
                width: 100%;
                height: 100%;
                object-fit:cover;
            }
        }

        &-overlay{
            position: absolute;
            width: 100%;
            height: 100%;
            transition: 0.5s all;
            z-index:2;
            &:hover{
                background-color:rgba(0, 0, 0, 0.719);
            }
            &:hover + .portfolio__item-img{
                transform: scale(1.2);
            }
        }
        &-block{
            display: flex;
            left: -100%;
            position:absolute;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            transition: 0.5s all;
            &-active{
                left: 0;
                
                transition: 0.5s all;
            }
        }

        &-text{
            color:#fff;
            font-size: 36px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        &-descr{
            color:#979797;
            text-transform: uppercase;
            font-weight: 600;
        }
    }
}