@media (max-width:1200px){
    
}

@media (max-width:992px){

}

@media (max-width:768px){
    .services{
        &__text{
            width:unset;
            font-size: 15px;
        }
    }
}

@media (max-width:576px){

}