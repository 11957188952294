@media (max-width:1200px){
    .portfolio{
        &__row{
            &-first{
                grid-template-columns: 480px;
                grid-template-rows: 617.5px repeat(2,480px);
                grid-auto-rows:480px
            }
            &-second{
                grid-template-columns: 480px;
                grid-template-rows: repeat(2,480px) 617.5px;
            }
        }
    }
}

@media (max-width:1023px){
    .portfolio{
        &__item{
            &-overlay{
                background-color:rgba(0, 0, 0, 0.719);
            }
            &-img{
                transform: scale(1.2);
            }
        }
    }
}

@media (max-width:992px){
    .portfolio{
        &__row{
            &-first{
                grid-template-columns: 375px;
                grid-template-rows: 512.5px repeat(2,375px);
                grid-auto-rows:375px
            }
            &-second{
                grid-template-columns: 375px;
                grid-template-rows: repeat(2,375px) 512.5px;
            }
        }

        &__item{
            &-text{
                font-size: 30px;
            }
            &-descr{
                font-size: 14px;
            }
            &-overlay{
                &:hover + .portfolio__item-img{
                    transform: scale(1.2);
                }
            }
        }
    }
}

@media (max-width:768px){
    .portfolio{
        &__wrapper{
            flex-wrap:wrap;
            margin-top: 60px;
        }

        &__row{
            &-first{
                grid-template-columns: 490px;
                grid-template-rows: 612.5px repeat(2,490px);
                grid-auto-rows:490px;
            }
            &-second{
                grid-template-columns: 490px;
                grid-template-rows: repeat(2,490px) 612.5px;
                a{
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }

            &-first, &-second{
                a{
                    margin-bottom: 10px;
                }
            }
        }

        &__item{
            &-text{
                font-size: 24px;
            }
            &-descr{
                font-size: 12px;
            }
            &-img{
                transform: scale(1);
            }
        }
    }
}

@media (max-width:576px){
    .portfolio{
        margin-bottom: 0;
        padding-bottom: 50px;
        &__row{
            &-first{
                grid-template-columns: 253px;
                grid-template-rows:316.5px repeat(2,253px);
                grid-auto-rows:253px;
            }
            &-second{
                grid-template-columns: 253px;
                grid-template-rows: repeat(2,253px) 316.5px;
                grid-auto-rows:auto;
                a{
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }


            &-first, &-second{
                a{
                    margin-bottom: 1px;
                }
            }
        }
        &__item{
            &-text{
                font-size: 21px;
            }
            &-descr{
                font-size: 10px;
            }
            &-img{
                transform: scale(1.2);
            }
        }
    }
}